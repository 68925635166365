import vaultAbi from "../abi/VaultUpgradeable/VaultUpgradeable.json"
import erc20Abi from '../abi/test/mock/MockERC20.json'
import rewardAbi from "../abi/reward/StakingMultiRewardsUpgradeable.json"
import ivAbi from "../abi/investmentVehicle/InvestmentVehicleSingleAssetBaseV1Upgradeable.json"
import scAbi from "../abi/selfcompounding/SelfCompoundingYieldUpgradeable.json"
import swapcenterAbi from "../abi/swapcenter/SwapCenter.json"
import motherVaultAbi from "../abi/GalacticVault/GalacticMotherVaultUpgradeable.json"
import colonyVaultAbi from "../abi/GalacticVault/GalacticColonyVaultUpgradeableBase.json"
import contractAddress from "../address/contractAddress.json"
import Web3 from "web3"

export function newContract(address, contractAbi) {
    try {
        const web3 = new Web3(window.ethereum)
        return new web3.eth.Contract(contractAbi, address)
    }
    catch (error) {
        console.log(error)
    }
}

//export const useVaultContract = newContract(contractAddress.vaultAddr, vaultAbi.abi)

//export const useRewardContract = newContract(contractAddress.msnxAddr, rewardAbi.abi)

export function useRewardContract(address) {
    return newContract(address, rewardAbi.abi)
}

export function useVaultContract(address) {
    return newContract(address, vaultAbi.abi)
}

export function useMotherVaultContract(address) {
    return newContract(address, motherVaultAbi.abi)
}

export function useColonyVaultContract(address) {
    return newContract(address, colonyVaultAbi.abi)
}

export function useTokenContract(address) {
    return newContract(address, erc20Abi.abi)
}

export function useIVContract(address) {
    return newContract(address, ivAbi.abi)
}

export function useSCContract(address) {
    return newContract(address, scAbi.abi)
}

export function useSwapCenterContract(network) {
    return newContract(contractAddress[network].core.SwapCenter, swapcenterAbi.abi)
}
