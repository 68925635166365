/** todo: change getting the base/long asset address from smart contract 
 * unify camel case
*/

import longvaultlist from "../address/vaults.json"
import baseCurrency from "./baseCurrency"
import tokenlist from "../address/token.json"
import { useVaultContract, useRewardContract, useIVContract, useColonyVaultContract, useMotherVaultContract } from "./useContract"
export default class vaultList{
    constructor(type) {
        this.type = type
        if (type === "long")
            this.vaultlist = longvaultlist.long
        else if (type === "galactic")
            this.vaultlist = longvaultlist.galactic
    }

    async createLongVault() {
        var VaultListObj = []
        for (var vault in this.vaultlist) {
            var BaseToken = new baseCurrency(tokenlist[this.vaultlist[vault].baseToken])
            var LongToken = new baseCurrency(tokenlist[this.vaultlist[vault].longToken])
            var RewardToken = []
            this.vaultlist[vault].rewardToken.forEach((rewardtoken) => {
                RewardToken.push(new baseCurrency(tokenlist[rewardtoken]))
            })
            var VaultContract = new useVaultContract(this.vaultlist[vault].address)
            var RewardContract = await new useRewardContract(this.vaultlist[vault].msnx)
            var subTokenList = []
            if (this.vaultlist[vault].acceptSubtoken) {
                subTokenList = this.vaultlist[vault].subToken
                subTokenList = subTokenList.map(
                    (tkn) => {
                        return tokenlist[tkn]
                    }
                )
            }
            //var RewardContract = useRewardContract
            VaultListObj.push({
                "id": this.vaultlist[vault].id,
                "name": this.vaultlist[vault].name,
                "chainId": this.vaultlist[vault].chainId,
                "baseToken": BaseToken, // pass in Token object here
                "longToken": LongToken, // pass in Token object here
                "baseTokenLink": this.vaultlist[vault].baseTokenLink,
                "baseTokenTooltipText": this.vaultlist[vault].baseTokenTooltipText,
                "longTokenLink": this.vaultlist[vault].longTokenLink,
                "longTokenTooltipText": this.vaultlist[vault].longTokenTooltipText,
                "rewardToken": RewardToken, // pass in List of Token object here
                "apy": "-",
                "value": "-",
                "address": this.vaultlist[vault].address,
                "vaultContract": VaultContract,
                "rewardContract": RewardContract,
                "accepSubToken":this.vaultlist[vault].accepSubToken,
                "subTokenList": subTokenList,
                "type":"long"
            })
        }
        return VaultListObj
    }

    async createGalacticVault() {
        var VaultListObj = []
        for (var vault in this.vaultlist) {
            
            var BaseToken = new baseCurrency(tokenlist[this.vaultlist[vault].baseToken])
            var LongToken = new baseCurrency(tokenlist[this.vaultlist[vault].longToken])
            var RewardToken = []
            this.vaultlist[vault].rewardToken.forEach((rewardtoken) => {
                RewardToken.push(new baseCurrency(tokenlist[rewardtoken]))
            })
            var VaultContract = await new useMotherVaultContract(this.vaultlist[vault].address)
            var RewardContract = await new useRewardContract(this.vaultlist[vault].msnx)
            var ColonyContractList = this.vaultlist[vault].colony.map(colony => (
                new useColonyVaultContract(colony)
            ))
            var subTokenList = []
            if (this.vaultlist[vault].acceptSubtoken) {
                subTokenList = this.vaultlist[vault].subToken
                subTokenList = subTokenList.map(
                    (tkn) => {
                        return tokenlist[tkn]
                    }
                )
            }
            //var RewardContract = useRewardContract
            VaultListObj.push({
                "id": this.vaultlist[vault].id,
                "name": this.vaultlist[vault].name,
                "chainId": this.vaultlist[vault].chainId,
                "baseToken": BaseToken, // pass in Token object here
                "longToken": LongToken, // pass in Token object here
                "baseTokenLink": this.vaultlist[vault].baseTokenLink,
                "baseTokenTooltipText": this.vaultlist[vault].baseTokenTooltipText,
                "longTokenLink": this.vaultlist[vault].longTokenLink,
                "longTokenTooltipText": this.vaultlist[vault].longTokenTooltipText,
                "rewardToken": RewardToken, // pass in List of Token object here
                "apy": "-",
                "value": "-",
                "address": this.vaultlist[vault].address,
                "vaultContract": VaultContract,
                "rewardContract": RewardContract,
                "accepSubToken":this.vaultlist[vault].accepSubToken,
                "subTokenList": subTokenList,
                "type":"galactic"
            })
        }
        return VaultListObj
    }

    async createVault() {
        if (this.type === "long")
            var VaultListObj = await this.createLongVault()
        else if (this.type === "galactic")
            var VaultListObj = await this.createGalacticVault()
        return VaultListObj
    }

    async getVaultList() {
        var VaultList = await this.createVault()
        return VaultList
    }
}