import { useEffect, useState } from "react"
import { getVaultApy, getValue } from "../utils/vaultInterface"
import "./css/Vault.scss"
import "./css/Tooltip.scss"
import hyperLinkIcon from "./img/hyperlink.svg"
import Tooltip from "./Tooltip"

const VaultItem = (props) => {
    const vaultInfo = props.vaultInfo
    const addClass = (props.addClass) ? props.addClass : ""
    const baseSymbol = (props.isTitle) ? vaultInfo.baseToken : vaultInfo.baseToken.symbol.toUpperCase()
    const longSymbol = (props.isTitle) ? vaultInfo.longToken : vaultInfo.longToken.symbol.toUpperCase()
    const baseImg = (props.isTitle) ? vaultInfo.baseToken : require("./img/" + baseSymbol + ".png").default
    const longImg = (props.isTitle) ? vaultInfo.longToken : require("./img/" + longSymbol + ".png").default
    const [apy, setApy] = useState(vaultInfo.apy)
    const [scapy, setSCApy] = useState(vaultInfo.apy)
    const [value, setValue] = useState(vaultInfo.value)
    const tooltipApyText = "Base -> Long Apy : " + apy
    const tooltipSCApyText = (scapy === "-%") ? "" : "Long Compounding Apy : " + scapy
    const apyDisplay = (scapy === "-%") ? apy : apy + "++"
    useEffect(async () => {
        if (props.vaultInfo.id !== -1) {
            var _apy = getVaultApy(props.vaultInfo)
            var _value = getValue(props.vaultInfo)
            var val = await _value
            if (props.vaultInfo.name !== "OLP(USDC:USDT) - WETH") {
                Promise.all([_apy, _value]).then((res) => {
                    setApy(res[0][0])
                    setSCApy(res[0][1])
                    setValue(Number(res[1]).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }))
                    props.addTotalValue(res[1])
                })
            }
            else {
                Promise.all([_apy, _value]).then((res) => {
                    setApy("Link")
                    setSCApy("-%")
                    setValue(Number(res[1]).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }))
                    props.addTotalValue(res[1])
                })
            }
            
        }
    }, [props.vaultInfo])

    return (

        <div>
            <div data-vault={vaultInfo.id} id="vault-item-container" onClick={props.onClick} className={addClass}>
                <div id="vault-name" data-vault={vaultInfo.id}>{vaultInfo.name}</div>
                <div id="tkn-container" data-vault={vaultInfo.id}>
                    {
                        (!props.isTitle) &&
                        <img id="tkn-img" data-vault={vaultInfo.id} src={baseImg} alt="" />
                    }
                    <div id="base-token" data-vault={vaultInfo.id}>
                        {
                            (vaultInfo.baseTokenTooltipText) &&
                            <div data-vault={vaultInfo.id} className="tooltip-container">
                                {baseSymbol}
                                <span className="tooltip" data-vault={vaultInfo.id}>
                                    <p data-vault={vaultInfo.id}>
                                        {vaultInfo.baseTokenTooltipText}
                                        {
                                            (vaultInfo.baseTokenLink) &&
                                            <a href={vaultInfo.baseTokenLink} data-vault={vaultInfo.id} target="_blank">
                                                <img src={hyperLinkIcon} data-vault={vaultInfo.id} alt="hyper link" />
                                            </a>
                                        }
                                    </p>

                                </span>
                            </div>
                        }
                        {
                            (!vaultInfo.baseTokenTooltipText) &&
                            <>
                                {baseSymbol}
                            </>
                        }
                    </div>
                </div>
                <div id="tkn-container" data-vault={vaultInfo.id}>
                    {
                        (!props.isTitle) &&
                        <img id="tkn-img" data-vault={vaultInfo.id} src={longImg} alt="" />
                    }
                    <div id="long-token" data-vault={vaultInfo.id}>
                        {
                            (vaultInfo.longTokenTooltipText) &&
                            <div data-vault={vaultInfo.id} className="tooltip-container">
                                {longSymbol}
                                <span className="tooltip" data-vault={vaultInfo.id}>
                                    <p data-vault={vaultInfo.id}>
                                        {vaultInfo.longTokenTooltipText}
                                        {
                                            (vaultInfo.longTokenLink) &&
                                            <a href={vaultInfo.longTokenLink} data-vault={vaultInfo.id} target="_blank">
                                                <img src={hyperLinkIcon} data-vault={vaultInfo.id} alt="hyper link" />
                                            </a>
                                        }
                                    </p>

                                </span>
                            </div>
                        }
                        {
                            (!vaultInfo.longTokenTooltipText) &&
                            <>
                                {longSymbol}
                            </>
                        }
                    </div>
                </div>
                {
                    (!props.isTitle && apy!=="Link") &&
                    <div id="apy" data-vault={vaultInfo.id}>
                        <div data-vault={vaultInfo.id} className="tooltip-container">
                            {apyDisplay}
                            <span className="tooltip" data-vault={vaultInfo.id}>
                                {tooltipApyText}
                                <br />
                                {tooltipSCApyText}
                            </span>
                        </div>
                    </div>
                    
                }
                {
                    (apy === "Link") &&
                    <div id="apy" data-vault={vaultInfo.id}>
                        <div data-vault={vaultInfo.id} className="tooltip-container">
                            {apyDisplay}
                            <span className="tooltip" data-vault={vaultInfo.id}>
                                <p data-vault={vaultInfo.id}>
                                    please refer to OolongSwap for the apy.
                                    <a href={"https://oolongswap.com/#/farm"} data-vault={vaultInfo.id} target="_blank">
                                        <img src={hyperLinkIcon} data-vault={vaultInfo.id} alt="hyper link" />
                                    </a>
                                </p>
                            </span>
                        </div>
                    </div>

                }
                {
                    (props.isTitle) &&
                    <div id="apy" data-vault={vaultInfo.id}>
                        {apy}
                    </div>
                }

                <div id="value" data-vault={vaultInfo.id}>{"$ " + value}</div>
            </div>
        </div>
    )
}
export default VaultItem
