import MetaMaskOnBoarding from "@metamask/onboarding"

import './App.scss';
import { BrowserRouter, Route, Switch } from "react-router-dom"
import React, { Component } from 'react';

// chain and address
import chainList from "./address/chainId.json"

// component
import SideBar from './components/SideBar'
import NavBar from "./components/NavBar"
import HomePage from "./components/HomePage"
import Vault from "./components/Vault"
import Test from "./components/Test"
import ChainWarningCard from './components/ChainWarningCard';
import Dashboard from './components/Dashboard';
import NotFound from './components/NotFound';

// utils
export default class App extends Component {
  constructor(props) {
    super(props)
    //this.state = this.state === JSON.parse(localStorage.getItem('state')) ? JSON.parse(localStorage.getItem('state')) :
    this.state=
    {
      "accountState": {
        "metaMaskInstalled": false,
        "metaMaskConnected": false,
        "account": [],
        "chainId": "",
      },
      "navvis":false
    }
    this.setState({ "currentPage": localStorage.getItem('page') ? localStorage.getItem('page') : "" })
  }


  updateAppState = (obj) => {
    this.setState(obj)
  }

  updateAccountState = (obj) => {
    this.accountState = this.state.accountState
    Object.keys(obj).forEach((key) => {
      this.accountState[key] = obj[key]
    })
    this.setState({
      "accountState": this.accountState
    })
    localStorage.setItem('state', JSON.stringify(this.state))
  }

  setPage = (page) => {
    this.setState({
      "currentPage": page
    })
    localStorage.setItem('page', page)
  }

  metaMaskStatusCheck = async () => {
    var metaMaskInstalled = await Boolean(window.ethereum && window.ethereum.isMetaMask)
    var metaMaskConnected = false
    var accounts = null
    var chainId = ""
    if (metaMaskInstalled) {
      window.ethereum.on('chainChanged', (_chainId) => {
        this.updateAccountState({
          "chainId": _chainId
        })
        this.setState({
          "chainId": _chainId
        })
      })

      window.ethereum.on('disconnect', (rpcError) => { console.log("disconnect") })

      window.ethereum.on('accountsChanged', async (_accounts) => {
        if (_accounts.length === 0) {
          this.updateAccountState({
            "metaMaskConnected": false,
            "account": [],
          })
          this.setState({
            "metaMaskConnected": false,
            "account": [],
          })
        }
        else if (_accounts !== this.state.accountState.accounts) {
          this.updateAccountState({
            "metaMaskConnected": true,
            "account": _accounts,
          })
          this.setState({
            "metaMaskConnected": true,
            "account": _accounts,
          })
        }
      })

      var _accounts = await window.ethereum.request({ method: "eth_accounts" })
      if (Boolean(_accounts && _accounts.length > 0)) {
        metaMaskConnected = true
        accounts = _accounts
      }
      chainId = await window.ethereum.request({ method: 'eth_chainId' })
    }
    this.setState({
      "accountState": {
        "metaMaskInstalled": metaMaskInstalled,
        "metaMaskConnected": metaMaskConnected,
        "account": accounts,
        "chainId": chainId,
      },
      "metaMaskInstalled": metaMaskInstalled,
      "metaMaskConnected": metaMaskConnected,
      "account": accounts,
      "chainId": chainId,
    })
  }


  installMetaMask = () => {
    const currentUrl = new URL(window.location.href)
    const forwarderOrigin = currentUrl.hostname === 'localhost' ?
      'http://localhost:3000' : undefined
    var onboarding = new MetaMaskOnBoarding({ forwarderOrigin })
    onboarding.startOnboarding();
  }

  connectMetaMask = async () => {
    try {
      await window.ethereum.request({ method: "eth_requestAccounts" })
    } catch (error) {
      console.error(error) //TODO: error handling
    }
    const accounts = await window.ethereum.request({ method: "eth_accounts" })
    this.updateAccountState({
      "metaMaskConnected": Boolean(accounts && accounts.length > 0),
      "account": accounts,
    })
    this.setState({
      "metaMaskConnected": Boolean(accounts && accounts.length > 0),
      "account": accounts,
    })
  }

  validChain = (chainId) => {
    var supportedChain = chainList.supported_chain
    return supportedChain.hasOwnProperty(chainId)
  }

  componentDidMount = async () => {
    this.metaMaskStatusCheck()
  }

  render() {
    var chainWarningDisplay = !this.validChain(this.state.accountState.chainId)
    return (
      <BrowserRouter>
        <SideBar page={this.state.currentPage} navvis={this.state.navvis} updateAppState={this.updateAppState}/>
        <NavBar title={this.state.currentPage} updateAppState={this.updateAppState} state={this.state} onInstallClick={this.installMetaMask} onConnectClick={this.connectMetaMask} />
        {
          (chainWarningDisplay && this.state.currentPage !== "LongLong Finance") &&
          <ChainWarningCard />
        }
        <Switch>

          <Route exact path="/">
            <HomePage setPage={this.setPage} />
          </Route>

          <Route exact path="/vault" render={() => (
            <Vault setPage={this.setPage} updateAppState={this.updateAppState} accountState={this.state} page="Long Vault" type="long" />
          )} />

          <Route exact path="/long" render={() => (
            <Vault setPage={this.setPage} updateAppState={this.updateAppState} accountState={this.state} page="Long Vault" type="long" />
          )} />

          <Route exact path="/galactic" render={() => (
            <Vault setPage={this.setPage} updateAppState={this.updateAppState} accountState={this.state} page="Galactic Vault" type="galactic" />
          )} />

          <Route path="/hodlandfeed" render={() => (
            <Vault setPage={this.setPage} updateAppState={this.updateAppState} accountState={this.state} page="Hodl & Feed Vault" type="hodl" />
          )} />

          <Route path="/dashboard">
            <Dashboard setPage={this.setPage} accountState={this.state} />
          </Route>

          <Route path="/test" render={() => (
            <Test setPage={this.setPage} accountState={this.state} />
          )} />

          <Route component={NotFound} />

        </Switch>
      </BrowserRouter>
    )
  }
}

