import tokenList from "../address/token.json"
import chainList from "../address/chainId.json"
import { newContract } from "../utils/useContract"
import lptknAbi from "../abi/lptokenabi.json"
import Web3 from "web3"
import BigNumber from "bignumber.js"

function usetokenContract(address) {
    return newContract(address, lptknAbi)
}

const tokenMapping = {
    "288": {
        "0x66a2a913e447d6b4bf33efbec43aaef87890fbbc":"0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
        "0x5de1677344d3cb0d7d465c10b72a8f60699C062d":"0xdac17f958d2ee523a2206206994597c13d831ec7",
        "0xf74195bb8a5cf652411867c5c2c5b8c2a402be35":"0x6b175474e89094c44da98b954eedeac495271d0f",
        "0xdeaddeaddeaddeaddeaddeaddeaddeaddead0000":"0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"
    }
}

export async function getCoinGekoPrice( addr, chainId, type = "usd" ) {
    //aave lptoken mapping
    const aaveTokenMap = new Map()
    aaveTokenMap.set(0x9719d867a500ef117cc201206b8ab51e794d3f82,0x2791bca1f2de4661ed88a30c99a7a9449aa84174)

    var curPrice
    if (chainId === 137) {
        if (aaveTokenMap.get(addr))
            addr = aaveTokenMap.get(addr)
            curPrice = await fetch(`https://api.coingecko.com/api/v3/coins/polygon-pos/contract/${addr}`).then(
            async (res) => {
                res = await res.json()
                if (type === "eth")
                    return res.market_data.current_price.eth
                return res.market_data.current_price.usd //number
            }
        )
    }
    else if (chainId === 288) {
        addr = tokenMapping[288][addr.toLowerCase()]
        var curPrice = await fetch(`https://api.coingecko.com/api/v3/coins/ethereum/contract/${addr}`).then(
            async (res) => {
                res = await res.json()
                return res.market_data.current_price.usd //number
            }
        )
    }
    else if (chainId === 250) {
        var curPrice = await fetch(`https://api.coingecko.com/api/v3/coins/fantom/contract/${addr}`).then(
            async (res) => {
                res = await res.json()
                if (type === "eth")
                    return res.market_data.current_price.eth
                return res.market_data.current_price.usd //number
            }
        )
    }
    else {
        curPrice = await fetch(`https://api.coingecko.com/api/v3/coins/ethereum/contract/${addr}`).then(
            async (res) => {
                res = await res.json()
                if (type === "eth")
                    return res.market_data.current_price.eth
                return res.market_data.current_price.usd //number
            }
        )
    }
    return curPrice
}

export async function getEthPrice() {
    var curPrice = await fetch(`https://api.coingecko.com/api/v3/coins/ethereum`).then(
        async (res) => {
            res = await res.json()
            return res.market_data.current_price.usd
        }
    )
    return curPrice
}

export async function getMaticPrice(type="usd") {
    var curPrice = await fetch(`https://api.coingecko.com/api/v3/coins/wmatic`).then(
        async (res) => {
            res = await res.json()
            if (type === "eth")
                    return res.market_data.current_price.eth
            return res.market_data.current_price.usd
        }
    )
    return curPrice
}

export const getLPTokenPrice = async ( addr, chainId ) => {
    // get total supply
    //this.useTokenContract = useTokenContract

    var tokenContract = await usetokenContract(addr)
    var totalSupply = await tokenContract.methods.totalSupply().call()

    // for token in subtoken list
    var totalValue = new BigNumber(0)
    var subtokens = tokenList[addr].subtokens
    await Promise.all(subtokens.map(async (token) => {
        var _price = getTokenPrice(token, chainId)
        if (token !== "ETH")
            var subtokenContract = usetokenContract(token)
        var _amount = (token === "ETH") ? getETHbalance(tokenList[addr].pooladdress) : subtokenContract.methods.balanceOf(tokenList[addr].pooladdress).call()
        var value = await Promise.all([_price, _amount]).then((res) => {
            var price = new BigNumber(res[0])
            var amount = new BigNumber(res[1]).shiftedBy(-1 * parseInt(tokenList[token].decimal))
            var value = price.times(amount)
            return value
        })
        totalValue = totalValue.plus(value)
    }))
    totalSupply = new BigNumber(totalSupply).shiftedBy(-1 * parseInt(tokenList[addr].decimal))
    return totalValue.div(totalSupply).toString()
}

export async function getTokenPrice(addr, type="usd") {
    //if (tokenList[addr].chainId !== 1 &&  tokenList[addr].chainId!==137)
    var supportedChain = chainList.supported_chain
    if(!supportedChain.hasOwnProperty("0x"+tokenList[addr].chainId.toString(16))) 
        return "unavailable"
    // return in string
    var price
    if (addr === "ETH") {
        price = await getEthPrice()
    }
    else if (addr.toLowerCase() === "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270") {
        price = await getMaticPrice()
    }
    else if (tokenList[addr] && tokenList[addr].isLPtoken) {
        price = await getLPTokenPrice(addr, tokenList[addr].chainId)
    }
    else {
        price = await getCoinGekoPrice(addr, tokenList[addr].chainId, type)
    }
    return price
}

export async function getETHbalance(addr) {
    var web3 = new Web3(window.ethereum)
    var amount = await web3.eth.getBalance(addr)
    return amount
}
