import { useEffect, useState, useRef } from "react"
import polygon from "../components/img/matic.svg"
import fantom from "../components/img/fantom.svg"
import mainnet from "../components/img/ethereum.svg"
import "./css/ChangeChainCard.scss"

export default function ChangeChainCard(props) {
    const [text1, setText1] = useState("")
    const [func1, setFunction1] = useState()
    const [chainImg1, setChainImg1] = useState()
    const [text2, setText2] = useState("")
    const [func2, setFunction2] = useState()
    const [chainImg2, setChainImg2] = useState()
    function switchToPolygon() {
        const Polygon_MAINNET_PARAMS = {
            chainId: '0x89',
            chainName: 'Matic Mainnet',
            nativeCurrency: {
                name: 'MATIC',
                symbol: 'MATIC',
                decimals: 18
            },
            rpcUrls: ['https://polygon-rpc.com/'],
            blockExplorerUrls: ['https://polygonscan.com/']
        }

        window.ethereum
            .request({
                method: 'wallet_addEthereumChain',
                params: [Polygon_MAINNET_PARAMS]
            })
        props.toggleCardDisplay()
    }

    function switchToFantom() {
        const FANTOM_MAINNET_PARAMS = {
            chainId: '0xfa',
            chainName: 'Fantom Opera',
            nativeCurrency: {
                name: 'Fantom',
                symbol: 'FTM',
                decimals: 18
            },
            rpcUrls: ['https://rpc.ftm.tools/'],
            blockExplorerUrls: ['https://ftmscan.com/']
        }
        
        window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [FANTOM_MAINNET_PARAMS]
        })
            .catch((error: any) => {
                console.log(error)
            })
        props.toggleCardDisplay()
    }

    function switchToEthereum() {
        const ETHEREUM_MAINNET_PARAMS = {
            chainId: '0x1',
        }

        window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [ETHEREUM_MAINNET_PARAMS]
        })
            .catch((error: any) => {
                console.log(error)
            })
        props.toggleCardDisplay()
    }

    useEffect(() => {
        if (props.chainID === "0x1") {
            setText1("Polygon")
            setFunction1(() => () => switchToPolygon())
            setChainImg1(polygon)
            setText2("Fantom Opera")
            setFunction2(() => () => switchToFantom())
            setChainImg2(fantom)
        }
        else if (props.chainID === "0x89") {
            setText1("Ethereum")
            setFunction1(() => () => switchToEthereum())
            setChainImg1(mainnet)
            setText2("Fantom Opera")
            setFunction2(() => () => switchToFantom())
            setChainImg2(fantom)
        }
        else if (props.chainID === "0xfa") {
            setText1("Ethereum")
            setFunction1(() => () => switchToEthereum())
            setChainImg1(mainnet)
            setText2("Polygon")
            setFunction2(() => () => switchToPolygon())
            setChainImg2(polygon)
        }
     },[props.chainID])
    

    
    return (
        <div className="card">
            <div className="changechaincard-container" ref={props.reference}>
                <div className="changechaincard-text" ref={props.reference}>
                    Chain Options:
                </div>
                <div id="chain-opt-btn" onClick={func1}>
                    <img src={chainImg1}/>
                    <div id="chain-opt-btn-text">{text1}</div>
                </div>
                <div id="chain-opt-btn" onClick={func2}>
                    <img src={chainImg2}/>
                    <div id="chain-opt-btn-text">{text2}</div>
                </div>
            </div>
        </div>
    )
    
    
}