import React from "react"
import "./css/Toggle.scss"

export const Toggle = React.memo((props) =>{
    return(
        <div id="toggle-switch">
            <input type="checkbox" onChange={props.toggleMode} defaultChecked></input>
            <span className="slider round"></span>
        </div>
    )
})