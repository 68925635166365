import BigNumber from "bignumber.js"
import { getTokenPrice, getMaticPrice } from "./tokenPrice"
import { useIVContract, useSCContract, useSwapCenterContract, useTokenContract } from "./useContract"
import contractAddress from "../address/contractAddress.json"
import tokenList from "../address/token.json"
import baseCurrency from "./baseCurrency"
import { createClient } from "urql"

// post data
export async function postData(url = "", data = {}) {
    const response = await fetch(url, {
        method: 'POST', 
        mode: 'cors', 
        cache: 'no-cache', 
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow', 
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(data) 
      });
    return response.json();
}

// get deposited asset info
export async function getDepositData(vault, account) {
    // get balance of account from baseToken
    const _availableBase = vault.baseToken.balanceOf(account[0])
    // get deposited Base from vault contract
    const _depositedBase = vault.vaultContract.methods.balanceOf(account[0]).call()

    const baseInfo = await Promise.all([_availableBase,_depositedBase])
    baseInfo[0] = await vault.baseToken.strNumToDisplay(baseInfo[0])
    baseInfo[1] = await vault.baseToken.strNumToDisplay(baseInfo[1])
    return ({
        "availableBase": baseInfo[0],
        "depositedBase": baseInfo[1],
    })
}

// Check Capacity
export async function checkCapacity(vault, amount, setCapacityWarning) {
    var _totalSupply = vault.vaultContract.methods.totalSupply().call()
    var _depositCap = vault.vaultContract.methods.depositCap().call()
    var full = await Promise.all([_totalSupply, _depositCap]).then((res) => {
        var totalSupply = BigNumber(res[0])
        var depositCap = BigNumber(res[1])
        if (totalSupply.plus(amount).comparedTo(depositCap) > 0) {
            setCapacityWarning(true)
            return true
        }
        setCapacityWarning(false)
        return false   
    })
    return full
}

// Deposit to Vault
export async function depositBase(vault, account, setPending, amount, setCapacityWarning, infiniteApprove) {
    var full = await checkCapacity(vault, vault.baseToken.displayToBigNum(amount), setCapacityWarning)
    if (full)
        return
    await vault.baseToken.checkAllowance(account, vault.address, vault.baseToken.displayToBigNum(amount), setPending, infiniteApprove)
    await vault.vaultContract.methods.deposit("0x"+vault.baseToken.displayToBigNum(amount).toString(16)).send({from:account[0]})
    .on('sent',(payload)=>{
        setPending({"pending":true})
    })
    .on('confirmation', (ConfirmationNum, receipt)=>{
        if (ConfirmationNum === 0) {
            setPending({ "success": true })
            window.setTimeout((() => { setPending({ "pending": false }); setPending({ "success": false })}), 1500)
        }    
    })
    .on('error', (error,receipt)=>{
        setPending({"pending":false, "error":true, "errorcode":error.code})
    })
}

// Deposit with other token
export async function depositToken(vault, tokenAddress, account, setPending, amount, setCapacityWarning, slipage, infiniteApprove) {
    var _tokenPrice = getTokenPrice(tokenAddress)
    var _baseAssetPrice = getTokenPrice(vault.baseToken.address)
    var price = await Promise.all([_tokenPrice, _baseAssetPrice])
    var token = new baseCurrency(tokenList[tokenAddress])
    amount = token.displayToBigNum(amount)
    var intendedDeposit = amount.shiftedBy(-1 * token.decimal).times(price[0]).div(price[1]).toString()
    var full = await checkCapacity(vault, vault.baseToken.displayToBigNum(intendedDeposit), setCapacityWarning)
    if (full)
        return
    slipage = BigNumber(slipage).div(100)
    var minimumDeposit = amount.minus(amount.times(slipage)).shiftedBy(-1 * token.decimal).times(price[0]).div(price[1]).shiftedBy(1 * vault.baseToken.decimal).toFixed(0,1)
    minimumDeposit = BigNumber(minimumDeposit)
    var swapcenterContract = new useSwapCenterContract(vault.chainId)
    await token.checkAllowance(account, contractAddress[vault.chainId].core.SwapCenter, amount, setPending, infiniteApprove)      
    await swapcenterContract.methods.swapExactTokenIn(tokenAddress, vault.address, "0x"+amount.toString(16), "0x"+minimumDeposit.toString(16)).send({from:account[0]})
    .on('sent',(payload)=>{
        setPending({"pending":true})
    })
    .on('confirmation', (ConfirmationNum, receipt)=>{
        if (ConfirmationNum === 0) {
            setPending({ "success": true })
            window.setTimeout((() => { setPending({ "pending": false }); setPending({ "success": false })}), 1500)
        }
            
    })
    .on('error', (error,receipt)=>{
        setPending({"pending":false, "error":true, "errorcode":error.code})
    })
}

// Withdraw
export async function withdrawBase(vault, account, setPending, amount) {
    await vault.vaultContract.methods.withdraw("0x"+vault.baseToken.displayToBigNum(amount).toString(16)).send({from:account[0]})
    .on('sent',(payload)=>{
        setPending({"pending":true})
    })
    .on('confirmation', (ConfirmationNum, receipt) => {
        if (ConfirmationNum === 0) {
            setPending({ "success": true })
            window.setTimeout((() => { setPending({ "pending": false }); setPending({ "success": false })}), 1500)
        }
    })
    .on('error', (error,receipt)=>{
        setPending({"pending":false, "error":true, "errorcode":error.code})
    })
}


// Get Vehicle List
export async function getVehicleList(contract) {
    var vehicleNum = await contract.methods.investmentVehiclesLength().call()
    var promiseList = []
    for (var i = 0; i < vehicleNum; i++){
        var _promise = contract.methods.getInvestmentVehicle(i).call()
        promiseList.push(_promise)
    }
    var vehicleAddressList = await Promise.all(promiseList)
    return vehicleAddressList
}

// Get Vault value
export async function getValue(vault) {
    if (!window.ethereum) return "-"
    if (!vault.vaultContract) return "-"
    var amount = await vault.vaultContract.methods.totalSupply().call()
    var price = await getTokenPrice(vault.baseToken.address)
    if (price === "unavailable")
        return 0
    amount = BigNumber(amount).shiftedBy(-1 * (parseInt(vault.baseToken.decimal)))
    price = BigNumber(price)
    var value = amount.times(price).toFixed(2)
    return value
}

// Get Vault Capacity
export async function getCapacity(vault) {
    var totalSupply = await vault.vaultContract.methods.totalSupply().call()
    var capacity = await vault.vaultContract.methods.depositCap().call()
    totalSupply = BigNumber(totalSupply)
    capacity = BigNumber(capacity)
    if (totalSupply.div(capacity).times(100).comparedTo(100) === 1)
        return [capacity.toString(), totalSupply.toString(), "100"]
    return [capacity.toString(), totalSupply.toString(), totalSupply.div(capacity).times(100).toString()]
}


/**
 * Yearn Apy
 * @param {*} vault 
 * @returns 
 */

// get Yearn Apy from vehicleList
export async function getYearnApyfromVehicle(yearnAPY, vehicleList) {
    var promiseList=[]
    vehicleList.forEach(async(vehicleAddr)=> {
        var ivContract = useIVContract(vehicleAddr)
        var _baseAsset = ivContract.methods.baseAsset.call().call()
        promiseList.push(_baseAsset)
    })
    var baseAssetList = await Promise.all(promiseList)
    var apyList = []
    baseAssetList.forEach((baseAsset) => {
        var apy = "unavailable"
        yearnAPY.forEach((yearnData) => {
            if (yearnData.token.address === baseAsset) {
                apy = yearnData.apy.net_apy
                return
            }
        })
        apyList.push(apy)
    })
    var accApy = 0;
    vehicleList = vehicleList.map((addr, idx) => {
        if (!isNaN(apyList[idx])&&!isNaN(accApy)) accApy += apyList[idx]
        else accApy = "unavailable"
        return ({
            "address": addr,
            "apy": apyList[idx],
        })
    })
    
    // avg over number of vehicles
    if (!isNaN(accApy)) {
        accApy /= vehicleList.length
        accApy *= 100
    } 
    return accApy;
}

// get Yearn Apy
export async function getYearnVaultApy(vault) {
    if (!window.ethereum) return "-"
    var yearnAPY = await fetch("https://api.yearn.finance/v1/chains/1/vaults/all").then(
        (_res) => {
            return _res.json()
        })
    var vehicleList = await getVehicleList(vault.vaultContract)
    var _firstLevelApy = getYearnApyfromVehicle(yearnAPY, vehicleList)
    
    // selfCompoundingYield Apy
    var selfCompoundingLongAsset = await vault.vaultContract.methods.selfCompoundingLongAsset.call().call()
    var addressCheck = new BigNumber(selfCompoundingLongAsset)
    var accApy
    var scApy
    if (!addressCheck.isZero()) {
        var scVaultConatrct = new useSCContract(selfCompoundingLongAsset)
        var secondLevelVehicleList = await getVehicleList(scVaultConatrct)
        var _secondLevelApy = getYearnApyfromVehicle(yearnAPY, secondLevelVehicleList)
        var Apys = await Promise.all([_firstLevelApy,_secondLevelApy])
        accApy = (!isNaN(Apys[0])) ? Apys[0].toFixed(2)+"% " : "-%"
        scApy = (!isNaN(Apys[1])) ? Apys[1].toFixed(2)+"% " : "-%"
    }
    else {
        let _accApy = await _firstLevelApy
        accApy = (!isNaN(_accApy)) ? _accApy.toFixed(2)+"% " : "-%"
        scApy = "-%"
    }
    
    vault.apy = accApy
    vault.scApy = scApy
    return [accApy,scApy]
}

// get Yearn Fantom Apy
export async function getYearnFantomVaultApy(vault) {
    if (!window.ethereum) return "-"
    var yearnAPY = await fetch("https://api.yearn.finance/v1/chains/250/vaults/all").then(
        (_res) => {
            return _res.json()
        })
    var vehicleList = await getVehicleList(vault.vaultContract)
    var _firstLevelApy = getYearnApyfromVehicle(yearnAPY, vehicleList)
    
    // selfCompoundingYield Apy
    var selfCompoundingLongAsset = await vault.vaultContract.methods.selfCompoundingLongAsset.call().call()
    var addressCheck = new BigNumber(selfCompoundingLongAsset)
    var accApy
    var scApy
    if (!addressCheck.isZero()) {
        var scVaultConatrct = new useSCContract(selfCompoundingLongAsset)
        var secondLevelVehicleList = await getVehicleList(scVaultConatrct)
        var _secondLevelApy = getYearnApyfromVehicle(yearnAPY, secondLevelVehicleList)
        var Apys = await Promise.all([_firstLevelApy,_secondLevelApy])
        accApy = (!isNaN(Apys[0])) ? Apys[0].toFixed(2)+"% " : "-%"
        scApy = (!isNaN(Apys[1])) ? Apys[1].toFixed(2)+"% " : "-%"
    }
    else {
        let _accApy = await _firstLevelApy
        accApy = (!isNaN(_accApy)) ? _accApy.toFixed(2)+"% " : "-%"
        scApy = "-%"
    }
    
    vault.apy = accApy
    vault.scApy = scApy
    return [accApy,scApy]
}


/**
 * Sushi Apy
 * @param {*} vault 
 * @returns 
 */

export async function assetPerYear(allocPoint, totalAllocPoint, rewardToken, rewardPerSecond) {
    var tokenPerYear = BigNumber(rewardPerSecond).shiftedBy(-1 * parseInt(tokenList[rewardToken].decimal)).times(3600).times(24).times(365).times(allocPoint).div(totalAllocPoint)
    var tokenPerDay = BigNumber(rewardPerSecond).shiftedBy(-1 * parseInt(tokenList[rewardToken].decimal)).times(3600).times(24).times(allocPoint).div(totalAllocPoint)
    var tokenprice = await getTokenPrice(rewardToken)
    return tokenPerYear.times(tokenprice).toString()
}

export async function getPoolTVL(pool) {
    var tokenPrice = await getTokenPrice(pool.pair)
    return BigNumber(pool.slpBalance).times(tokenPrice).shiftedBy(-1*parseInt(tokenList[pool.pair].decimal)).toString()
}

// todo: change getSushiVaultApy to investment vehicle based version
export async function getSushiVaultApy(vault) {
    if (!window.ethereum) return "-"
    var sushiSwapInfo = await postData("https://api.thegraph.com/subgraphs/name/sushiswap/matic-minichef", {
        "operationName": "miniChefPoolsQuery",
        "query": "query miniChefPoolsQuery($first: Int! = 1000, $skip: Int! = 0, $orderBy: String! = \"id\", $orderDirection: String! = \"desc\") {\n  pools(first: $first, skip: $skip, orderBy: $orderBy, orderDirection: $orderDirection) {\n    id\n    pair\n    rewarder {\n      id\n      rewardToken\n      rewardPerSecond\n    }\n    allocPoint\n    lastRewardTime\n    accSushiPerShare\n    slpBalance\n    userCount\n    miniChef {\n      id\n      sushiPerSecond\n      totalAllocPoint\n    }\n  }\n}\n"
    })
    var accApy
    var scApy
    sushiSwapInfo = sushiSwapInfo.data.pools
    await Promise.all(sushiSwapInfo.map(async(pool) => {
        if (pool.pair === vault.baseToken.address.toLowerCase()) {
            // sushi reward
            let _maticYields = assetPerYear(pool.allocPoint, pool.miniChef.totalAllocPoint, pool.rewarder.rewardToken, pool.rewarder.rewardPerSecond)
            let _sushiYields = assetPerYear(pool.allocPoint, pool.miniChef.totalAllocPoint, "0x0b3f868e0be5597d5db7feb59e1cadbb0fdda50a", pool.miniChef.sushiPerSecond)
            let _tvl = getPoolTVL(pool)
            var apy = await Promise.all([_maticYields, _sushiYields, _tvl]).then(
                (res) => {
                    return BigNumber(res[0]).plus(res[1]).div(BigNumber(res[2])).times(100).toFixed(2).toString()  
                })
            accApy = apy+" %"
            scApy = "-%"
        }
    }))
    return [accApy,scApy]
    
}

export async function getAaveMaticApy(vault) {
    const apiUrl = "https://api.thegraph.com/subgraphs/name/aave/aave-v2-matic"
    const apiQuery =`{
        reserves {
          name
          underlyingAsset
          
          liquidityRate 
          stableBorrowRate
          variableBorrowRate
          
          aEmissionPerSecond
          vEmissionPerSecond
          sEmissionPerSecond
          
          totalATokenSupply
          totalCurrentVariableDebt
        }
      }`
    const client = createClient({ url: apiUrl })
    let data = await client.query(apiQuery).toPromise()
    data = data.data.reserves
    data = data.filter(d => (d.underlyingAsset.toLowerCase() === vault.baseToken.address.toLowerCase()))[0]
    const ray = BigNumber(10).pow(27)
    const secondsPerYear = BigNumber(31536000)
    let aEmissionPerYear = data.aEmissionPerSecond * secondsPerYear
    let rewardPriceEth = await getMaticPrice("eth")
    let decimals = BigNumber(1).shiftedBy(Number(vault.baseToken.decimal)).div(BigNumber(1e18))
    let tokenPriceEth = await getTokenPrice(vault.baseToken.address, "eth")
    let incentiveDepositAPR = ((aEmissionPerYear * rewardPriceEth) /
        (data.totalATokenSupply * tokenPriceEth)) * decimals
    let depositAPR = BigNumber(data.liquidityRate).div(ray).plus(incentiveDepositAPR).toFixed(3)
    let depositAPY = (Math.pow(1 + (depositAPR / secondsPerYear), secondsPerYear) - 1) * 100
    
    let accApy = depositAPY.toFixed(2) + "% "
    let scApy = "-%"
    vault.apy = accApy
    vault.scApy = scApy
    return [accApy,scApy]
}

export async function getAaveAvaxApy(vault) {
    const apiUrl = "https://api.thegraph.com/subgraphs/name/aave/protocol-v2-avalanche"
    const apiQuery =`{
        reserves {
          name
          underlyingAsset
          
          liquidityRate 
          stableBorrowRate
          variableBorrowRate
          
          aEmissionPerSecond
          vEmissionPerSecond
          sEmissionPerSecond
          
          totalATokenSupply
          totalCurrentVariableDebt
        }
      }`
    const client = createClient({ url: apiUrl })
    let data = await client.query(apiQuery).toPromise()
    data = data.data.reserves
    data = data.filter(d => (d.underlyingAsset.toLowerCase() === vault.baseToken.address.toLowerCase()))[0]
    const ray = BigNumber(10).pow(27)
    const secondsPerYear = BigNumber(31536000)
    let aEmissionPerYear = data.aEmissionPerSecond * secondsPerYear
    let rewardPriceEth = await getMaticPrice("eth")
    let decimals = BigNumber(1).shiftedBy(Number(vault.baseToken.decimal)).div(BigNumber(1e18))
    let tokenPriceEth = await getTokenPrice(vault.baseToken.address, "eth")
    let incentiveDepositAPR = ((aEmissionPerYear * rewardPriceEth) /
        (data.totalATokenSupply * tokenPriceEth)) * decimals
    let depositAPR = BigNumber(data.liquidityRate).div(ray).plus(incentiveDepositAPR).toFixed(3)
    let depositAPY = (Math.pow(1 + (depositAPR / secondsPerYear), secondsPerYear) - 1) * 100
    let accApy = depositAPY.toFixed(2) + "% "
    let scApy = "-%"
    vault.apy = accApy
    vault.scApy = scApy
    
    return [accApy,scApy]
}


export async function getVaultApy(vault) {
    if (vault.type === "galactic" && vault.chainId==="0x120")
        return getAaveMaticApy(vault)
    else if (vault.type === "galactic" && vault.chainId==="0xa86a")
        return getAaveAvaxApy(vault)
    else if (vault.chainId === "0x1")
        return getYearnVaultApy(vault)
    else if (vault.chainId === "0xfa")
        return getYearnFantomVaultApy(vault)
    else if (vault.chainId === "0x89")
        return getSushiVaultApy(vault)
}

export async function baseInVault(vaultAddress, vaultContract) {
    let baseAsset = await vaultContract.methods.baseAsset.call().call()
    let tknContract = new useTokenContract(baseAsset)
    let balance = await tknContract.methods.balanceOf(vaultAddress).call()
    return balance
}
