import { Component, createRef } from "react"
import "./css/NavBar.scss"
import chainDict from "../address/chainId.json"
import ChangeChainCard from "./ChangeChainCard"
import polygon from "../components/img/matic.svg"
import fantom from "../components/img/fantom.svg"
import mainnet from "../components/img/ethereum.svg"
import { Link } from "react-router-dom"
import logo from "./img/longlogo.svg"
import list from "./img/list.svg"


export default class NavBar extends Component {
    constructor(props) {
        super(props)
        this.onInstallClick = this.props.onInstallClick
        this.onConnectClick = this.props.onConnectClick
        this.state = {
            showChangeChainCard: false,
        }
        this.wrapperRef = createRef()
        this.buttonRef = createRef()
    }

    closeCard = () => {
        this.setState({ "showChangeChainCard": false })
    }
    
        toggleCardDisplay = () => {
        this.setState((prevstate) => ({
            showChangeChainCard:!prevstate.showChangeChainCard
        }))
    }


    detectClick = (e) => {
        if ((this.buttonRef.current && !this.buttonRef.current.contains(e.target))&&(this.wrapperRef.current && !this.wrapperRef.current.contains(e.target))) {
            this.closeCard()
        }
    }

    detectKey = (e) => {
        if (e.key === "Escape")
        this.closeCard()
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.detectClick)
        document.addEventListener("keydown", this.detectKey, false)
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.detectClick)
        document.removeEventListener("keydown", this.detectKey, false)
    }

    setNavViz() {
        this.props.updateAppState({ "navvis": !this.props.state.navvis })
        //this.setState((prevstate) => ({ "navvis": !prevstate.navvis }))
    }

    render() {
        this.accountState = this.props.state.accountState
        if (this.accountState.metaMaskInstalled) {
            var displayChainBtn = false
            var btnValue = "connect metamask"
            var btnOnClick = this.onConnectClick
            if (this.accountState.metaMaskConnected && this.accountState.account) {
                displayChainBtn = true
                var chainBtnValue = chainDict[this.accountState.chainId]
                if (this.accountState.chainId === "0x1") {
                    var chainImg = mainnet
                    var chainImgAlt = "Mainnet Icon"
                }
                else if (this.accountState.chainId === "0x89") {
                    var chainImg = polygon
                    var chainImgAlt = "Polygon Icon"
                }
                else if (this.accountState.chainId === "0xfa") {
                    var chainImg = fantom
                    var chainImgAlt = "Fantom Opera Icon"
                }
                btnValue = this.accountState.account[0].substr(0, 6) + "..." + this.accountState.account[0].substr(-4)
            }
        }
        else {
            var displayChainBtn = false
            var btnValue = "install metamask"
            var btnOnClick = this.onInstallClick
        }
        if (this.props.state.pending === true) {
            var displayChainBtn = true
            var btnValue = "pending"
            var addClass  ="grey"
        }
        if (this.props.state.success === true) {
            var displayChainBtn = true
            var btnValue = "success"
            var addClass  ="yellow"
        }
        var addNavCalss = (this.props.title === "LongLong Finance") ? " homepage" : ""
        return (
            <>
                <div id="navbar-container" className={"page-container" + addNavCalss}>
                    <div id="list-btn">
                        <img src={list} alt="" onClick={()=>this.setNavViz()}/>
                    </div>
                    <div id="logo-img">
                        <Link to="/">
                            <img src={logo} alt="LongLong Finance" />
                        </Link>
                    </div>
                    <h1 id="navbar-title">{this.props.title}</h1>
                    <div className="btn-container">
                            {(displayChainBtn) &&
                                <div id="chain-btn" ref={this.buttonRef} onClick={this.toggleCardDisplay}>
                                    {(chainImg) && <img src={chainImg} alt={chainImgAlt} />}
                                    <div id="chain-btn-text">{chainBtnValue}</div>
                                </div>
                        }
                            <input className={addClass ? addClass : ""} id="metamask-btn" type="button" value={btnValue} onClick={btnOnClick}/>
                    </div>
                </div>
            {
                (this.state.showChangeChainCard) &&
                    <ChangeChainCard reference={this.wrapperRef} chainID={this.accountState.chainId} show={this.state.showChangeChainCard} toggleCardDisplay={this.toggleCardDisplay} closeCard={() => { this.setState({"showChangeChainCard":false})}}/>
                }
            </>
        )
    }
}
